import React from 'react';
import { Page, Document, Image, StyleSheet, View, Text } from '@react-pdf/renderer';
import InvoiceTitle from './InvoiceTitle'
import InvoiceItemsTable from './InvoiceItemsTable'
import logo from '../mpl-logo.png'


const styles = StyleSheet.create({
    page: {
        paddingTop: 150, paddingBottom: 165,
        paddingLeft: 10,
        fontSize: 8,
        lineHeight: 1.5,
        flexDirection: 'column'
    },
    box: { width: '100%', marginBottom: 30, borderRadius: 5 },
    pageNumbers: {
        position: 'absolute',
        bottom: 20,
        left: 0,
        right: 0,
        textAlign: 'center'
    },

    logo: {
        width: 130,
        height: 60,
        marginTop: 0
    },
    barcode: {
        width: 145,
        height: 18,
        marginLeft: '5em'
    }
});


const Invoice = ({ invoice }) => {
    console.log("LabOrder =>Invoice 38 "+ JSON.stringify(invoice.reports))
    return (<Document>
        <Page style={styles.page} size="A5" wrap
        >
            <View fixed style={{
                width: '100%', display: 'flex', flexDirection: 'row', top: 0, position: 'absolute',
                paddingLeft: 25,
                alignItems: "center",
                paddingRight: 10,
                paddingTop: 10,
                // backgroundColor:"red"

                // paddingTop:invoice.reports[0].group_title !=""?-5: 10
            }} >
                <View
                    style={{
                        width: "30%",
                        // marginRight:"-10%"
                    }}
                >
                    <Image style={[styles.logo,{marginTop:invoice.reports[0].group_title !=""?-20:0}]} src={logo} />
                </View>
                <View style={{ marginLeft: 10, width: "30%" }} >
                    <Text style={{
                        color: 'black',
                        fontSize: 13,
                        marginLeft: 17,
                       fontFamily: 'Helvetica-Bold',
                        textDecoration: 'underline',

                    }}>LAB ORDER</Text>

                    <View
                        style={{
                            flexDirection: "column"
                        }}>

                        <View style={{
                            margin: 'auto',
                            //chk 28-dec es ka size change krna hai
                             width: 72, 
                            //  width: 70, 

                             backgroundColor: '#F2F0F0', borderWidth: 1,
                            borderColor: 'black'
                        }}>
                            <Text style={{ paddingTop: '3em', paddingLeft: '3em', paddingRight: '3em', textAlign: 'center', fontFamily: 'Helvetica-Bold',
                            //  fontSize: 7
                            //chk 28-dec es ka size change krna hai
                             fontSize: 7.5
                              }}>FSLS ID : {invoice.invoice_id}</Text>
                        </View>
                        <View style={{
                            margin: 'auto', width: 70

                        }}>
                            <Text style={{ paddingTop: '3em', paddingLeft: '3.5em', paddingRight: '3.5em', textAlign: 'center', fontFamily: 'Helvetica-Bold', fontSize: 7.5, textDecoration: 'underline' }}>MR # : {invoice.patient_id}</Text>
                        </View>
                    </View>

                </View>
                <View style={{ marginLeft: -10, marginTop: 10, width: "40%" }}  >
                <Text style={{
                            fontFamily: 'Helvetica-Bold',
                            fontSize: 5,
                            textAlign:"right",
                            marginBottom: 1,
                            marginTop:3


                        }}>{"DOC No:FSLS-GEN-F046 | Issue No: 01 | Issue Date:27-05-2022"}</Text>
                    <Image style={styles.barcode} src={invoice.barcode} />

                    <Text style={{
                        marginLeft: '5em', fontFamily: 'Helvetica-Bold', fontSize: 10,
                        marginBottom: 10,
                        marginTop: 5
                    }}>{invoice.toAddress + " " + invoice.name}</Text>
                       {/* {invoice.invoice_id==2101409 || invoice.invoice_id==2701404 ||invoice.invoice_id==1901385 ? */}
                       {/* {invoice.invoice_id==2101409 || invoice.invoice_id==2701404 ||invoice.invoice_id==1901385||invoice.invoice_id==5801467 || invoice.invoice_id== "0401479" ||invoice.invoice_id==9701419||invoice.invoice_id==5801466 || invoice.invoice_id==5901519  ? */}
                       {/* {invoice.invoice_id==2101409 || invoice.invoice_id==2701404 ||invoice.invoice_id==1901385||invoice.invoice_id==5801467 || invoice.invoice_id== "0401479" ||invoice.invoice_id==9701419||invoice.invoice_id==5801466 || invoice.invoice_id==5901519 || invoice.invoice_id==7101439 ||invoice.invoice_id==4401512||invoice.invoice_id==9201456 || invoice.invoice_id== 9201457  ? */}
                       {/* {(invoice.age_gender.includes("6739")) ||(invoice.age_gender.includes("6740"))? this will apply for other than human because age is calculated from hazrat adam jab 6740 se b zyada ho gae to es me aur include dal den ge*/}
                  
                       {(invoice.age_gender.includes("6739")) ||(invoice.age_gender.includes("6740"))||(invoice.age_gender.includes("6741"))?
                     <Text style={{
                        marginLeft: '5em', paddingTop: '5em', fontSize: 7,
                        borderTopColor: 'black',
                        borderBottomColor: 'black',
                        borderBottomWidth: 0.75,
                        borderTopWidth: 0.75

                    }}>Age/Gender :  N/A</Text>: <Text style={{
                        marginLeft: '5em', paddingTop: '5em', fontSize: 7,
                        borderTopColor: 'black',
                        borderBottomColor: 'black',
                        borderBottomWidth: 0.75,
                        borderTopWidth: 0.75

                    }}>Age/Gender :  {invoice.age_gender}</Text> }
                    {/* <Text style={{
                        marginLeft: '5em', paddingTop: '5em', fontSize: 7,
                        borderTopColor: 'black',
                        borderBottomColor: 'black',
                        borderBottomWidth: 0.75,
                        borderTopWidth: 0.75

                    }}>Age/Gender :  {invoice.age_gender}</Text> */}
                      {/* // for new MR panel 3-JAN-2023 */}

                      {
                        invoice.if_panelMR_cnic_father&& invoice.father_husband!="null"
                            ? <Text style={{
                                marginLeft: '5em', paddingTop: '5em', fontSize: 7,
                                borderBottomColor: 'black',
                                borderBottomWidth: 0.75,
                            }}>Father/Husband : {invoice.father_husband}</Text>
                            : null
                    }
                      {
                        invoice.if_panelMR_cnic_father&& invoice.cnic!=""
                            ? <Text style={{
                                marginLeft: '5em', paddingTop: '5em', fontSize: 7,
                                borderBottomColor: 'black',
                                borderBottomWidth: 0.75,
                            }}>CNIC: {invoice.cnic}</Text>
                            : null
                    }
                    <Text style={{
                        marginLeft: '5em', paddingTop: '5em', fontSize: 7,
                        borderBottomColor: 'black',
                        borderBottomWidth: 0.75,
                    }}>Contact # :  {invoice.contact}</Text>
                    <Text style={{
                        marginLeft: '5em', paddingTop: '5em', fontSize: 7,
                        borderBottomColor: 'black',
                        borderBottomWidth: 0.75,
                    }}>Ref By : {invoice.Ref_By}</Text>
                    {
                        invoice.Ref_No != ""
                            ? <Text style={{
                                marginLeft: '5em', paddingTop: '5em', fontSize: 7,
                                borderBottomColor: 'black',
                                borderBottomWidth: 0.75,
                            //chk 28-dec es ko bold krna hai
                                fontFamily: 'Helvetica-Bold'
                            }}>Ref No : {invoice.Ref_No}</Text>
                            : null
                    }

                </View>
            </View>
            <View style={{ width: '100%',
            //  marginTop:invoice.if_panelMR_cnic_father? -30:-60,
            marginTop:invoice.cnic!=""&&invoice.father_husband=="null"&&invoice.if_panelMR_cnic_father==true ? -20:invoice.father_husband!="null"&&invoice.cnic==""&&invoice.if_panelMR_cnic_father==true? -20:invoice.father_husband!="null" &&invoice.cnic!=""&&invoice.if_panelMR_cnic_father==true ?-30:-50,
             
             paddingRight: 10, paddingLeft: 10 }}

            >

                <InvoiceItemsTable invoice={invoice}
                />

            </View>
            <View fixed style={{ position: 'absolute', width: '100%', bottom: 0 ,}}>
            {/* //add group title seprate 1801348 9-jan-2023 by sir najam updation and add extra box Ref no check by and one line Note add  */}


                <View style={{
                    width: "100%",
                    flexDirection: 'row',
                    marginTop: 10,
                    marginLeft:5,
                    // backgroundColor:"grey",
                    justifyContent:"space-between"
                }}>
                       <View style={{
                        fontFamily: 'Helvetica-Bold',
                        flexDirection:"column",
                        borderWidth:0.25,
                        // padding:20,
                        padding:10,

                        paddingBottom:5,
                        paddingTop:5,
                        width: "30%",
                        
                        justifyContent:"space-between",
                        marginTop: 1,
                        fontSize: 10
                    }}>
                        <Text style={{textAlign:"center"}}>{"Ref. No Checked by"}</Text>
                        <Text style={{paddingTop:20,textAlign:"center"}}>{"________________"}</Text>
                        </View>
                    <View style={{
                        fontFamily: 'Helvetica-Bold',
                        flexDirection:"column",
                        borderWidth:0.25,
                        // padding:20,
                        padding:10,


                        paddingBottom:5,
                        paddingTop:5,
                        width: "30%",
                        
                        justifyContent:"space-between",
                        marginTop: 1,
                        fontSize: 10,
                        marginLeft:"1%",
                        // backgroundColor:"blue"
                    }}>
                          <Text style={{textAlign:"center"}}>{"Test Run by"}</Text>
                        <Text style={{paddingTop:20,textAlign:"center"}}>{"_______________"}</Text>
                      
                        </View>
                    {/* <Text style={{
                        fontFamily: 'Helvetica-Bold',
                        textAlign: 'center',
                        marginTop: 1,
                        width: "5%",
                        fontSize: 10,
                        // backgroundColor:"white"
                    }}>{""}</Text> */}
                  
                  
                        {/* <Text style={{
                        fontFamily: 'Helvetica-Bold',
                        textAlign: 'center',
                        marginTop: 1,
                        width: "5%",
                        fontSize: 10,
                        // backgroundColor:"white"
                    }}>{""}</Text> */}
                    <View style={{
                        fontFamily: 'Helvetica-Bold',
                        flexDirection:"column",
                        borderWidth:0.25,
                        // padding:20,
                        padding:10,

                        paddingBottom:5,
                        paddingTop:5,
                        width: "30%",
                        
                        justifyContent:"space-between",
                        marginTop: 1,
                        fontSize: 10,
                        marginRight:"1%"
                    }}>
                        <Text style={{textAlign:"center",}}>{"Result Taken by"}</Text>
                        <Text style={{paddingTop:20,textAlign:"center"}}>{"_______________"}</Text>
                        </View>
                </View>
                {/* <View style={{
                    width: "100%",
                    flexDirection: 'row',
                    marginTop: 5,
                    marginLeft:5
                }}>

                    <Text style={{
                        fontFamily: 'Helvetica-Bold',
                        textAlign: 'center',
                        marginTop: 1,
                        width: "30%",
                        fontSize: 12
                    }}>{"Test Run By"}</Text>
                    <Text style={{
                        fontFamily: 'Helvetica-Bold',
                        textAlign: 'center',
                        marginTop: 1,
                        width: "40%",
                        fontSize: 10
                    }}>{""}</Text>
                    <Text style={{
                        fontFamily: 'Helvetica-Bold',
                        textAlign: 'center',
                        marginTop: 1,
                        width: "30%",
                        fontSize: 12
                    }}>{"Result Taken By"}</Text>
                </View> */}
                {/* <View style={{
                    paddingTop: 5,
                    paddingLeft: 10,
                    paddingBottom: 5,
                    paddingRight: 80,
                    flexDirection: 'column',
                    backgroundColor:"red"
                }}>
                    <Text style={{
              
            //   padding: 2,
              width: "100%",
            //   borderTopColor: 'black',
            //   borderTopWidth: 0.5,
              fontSize: 7,
              color: 'black'
              }}>Note: Mention date and time which doing & signing above checks.</Text>
                </View> */}
                <View style={{
                    position:"absolute",
                    
                    flexDirection: 'column',
                    backgroundColor:"red",
                    top:78,
                    left:6
                }}>
                    <Text style={{
              
            //   padding: 2,
              width: "100%",
            //   borderTopColor: 'black',
            //   borderTopWidth: 0.5,
              fontSize: 7,
              color: 'black'
              }}>Note: Sign & Mention date & time of performing above checks.</Text>
                </View>
                  {/* <Text style={{

textAlign: 'start',
paddingTop:10,
padding: 2,
width: "100%",
borderTopColor: 'black',
borderTopWidth: 0.5,
fontSize: 7,
color: 'black'
}}>
{"User : "}<Text style={{
    fontFamily: 'Helvetica-Bold'
}}>{invoice.User_Name}</Text>
</Text> */}<Text fixed style={{ width: "100%", left: 0, paddingLeft: 20, position: "absolute", fontSize: 6,top:130 }} render={({ pageNumber, totalPages }) => (
    "Page " + `${pageNumber} / ${totalPages}`
    )} />
                <View style={{
                    paddingTop: 30,
                    paddingLeft: 90,
                    paddingBottom: 30,
                    paddingRight: 80,
                    flexDirection: 'column',
                    // backgroundColor:"yellow"
                }}>
                    {/* <Text fixed style={{paddingRight: -80,
                    paddingLeft: -90,
                }}>Note: Mention date and time which doing & signing above checks.</Text> */}




                    <Text style={{

                        textAlign: 'center',
                        padding: 2,
                        width: "100%",
                        borderTopColor: 'black',
                        borderTopWidth: 0.5,
                        fontSize: 7,
                        color: 'black',
                        // backgroundColor:"red"
                    }}>
                        {"User : "}<Text style={{
                            fontFamily: 'Helvetica-Bold'
                        }}>{invoice.User_Name}</Text>
                    </Text>
                    <Text style={{

                        textAlign: 'center',
                        padding: 2,
                        width: "100%",
                        borderTopColor: 'black',
                        borderTopWidth: 0.5,
                        borderBottomWidth: 0.5,
                        fontSize: 7,
                        color: 'black'
                    }}>
                        {"Date Book : "}<Text style={{
                            fontFamily: 'Helvetica-Bold'
                        }}>{invoice.invoice_date}</Text>{" at "}<Text style={{
                            fontFamily: 'Helvetica-Bold'
                        }}>{invoice.invoice_time}</Text>
                    </Text>

                </View>
            </View>



        </Page>
    </Document>
    )
}

export default Invoice